@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap&subset=latin,latin-ext');

@backgroundcolor: #F9F9F9;
@white: white;
@tabborder-color: #8692A6;
@tabtext-color: #8692A6;
@button-success: #5ABD8C;
@input-border: #8692A6;

html {
    width: 100%;
    height: 100%;
    background: '#f8f8f8';
}

#root {
    height: 100%;
}

:not(i) {
    font-family: 'Poppins', sans-serif !important;
}

body {
    font-size: 0.8rem;
    font-weight: 400;
    color: '#3a3a3a';
    background: '#f8f8f8';
}

body .background {
    height: 100%;
}

body .background main {
    margin: 0 !important;
    height: 100%;
}

body .background main .container {
    height: 100%;
}

body .background main .container .sub-hidden {
    margin-left: 90px + 60px;
}

body .background main .container .main-hidden {
    margin-left: 60px;
}

a {
    color: #3a3a3a !important;
    transition: color 200ms;
}

.text-muted {
    color: #909090 !important;
    font-size: 13px !important;
}

// .filterBox {
//     min-width: 250px;
//     min-height: 100px;
//     padding: 20px;
//     background-color: #fff;
//     box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
// }

// .filterBox .filterTitle {
//     font-size: 15px;
//     font-weight: 500;
//     width: 100%;
//     margin-bottom: 20px;
//     padding-bottom: 10px;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.125);

// }

.loader-spinner {
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, .5019607843137255);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.ant-select-item {
    min-height: 40px !important;
    line-height: 30px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @primary-color !important;
    color: white !important;
}

.ant-pagination-total-text {
    width: 100%;
    text-align: right;
}

.profileAvatar {
    padding: 5px;
    margin: 5px;
    border: solid 1px #FFF;
}

.phone-Input .ant-input-prefix {
    margin-right: 0;
    color: black !important;

}

.header-navbar {
    margin-right: 5px;
    user-select: none;
}

.header-img {
    margin-right: 5px;
}

.header-fixed {
    background-color: #FFFFFF !important;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}


.pointer {
    cursor: pointer;
}

.userSelectNone {
    user-select: none;
}

.mt-64 {
    margin-top: 64px;
}

.mt--15 {
    margin-top: -15px;
}

.w-100 {
    width: 100%;
}

/*Header*/
.header {
    background-color: #FFFFFF !important;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2 !important;

    .header-bufiicon-btn {
        border: none;
        transition: none;
        box-shadow: none;

        .header-bufiicon {
            position: absolute;
            left: -35px;
            top: -5px;
        }
    }

    .header-navbar {
        margin-right: 5px;
        user-select: none;

        .header-img {
            margin-right: 5px;
        }
    }
}

/*Login*/
.authwrapper_container {
    min-height: 100vh;
    background-color: @backgroundcolor !important;

    input {
        border-bottom: 1px solid @tabborder-color !important;
        border-radius: 0;
    }

    .authwrapper_row {
        padding: 0 30px;
        margin: 0;
        min-height: 80vh;
    }

    .login_card {
        background-color: @backgroundcolor;
        border: 0;

        .link_button {
            color: #8692A6;
            font-size: 12px;
        }
    }

    .authwrapper_footer {
        background: @primary-color;
        color: #FFF;
        text-align: center;
    }

    .loginTabContainer .ant-tabs {
        border: 1px solid @tabborder-color;
        border-radius: 20px;
        overflow: hidden;
    }

    .loginTabContainer .ant-tabs-nav {
        background-color: @backgroundcolor;
    }

    .loginTabContainer .ant-tabs-nav-wrap {
        background: @backgroundcolor;
    }

    .loginTabContainer .ant-tabs-nav-more {
        display: none;
    }

    .loginTabContainer .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
        border-radius: 0 0 20px 0 !important;
        display: inline;
        flex: 1;
        text-align: center;
        font-weight: 600;
        background-color: white;
        height: 54px;
        line-height: 34px;
        margin: 0;
        outline: 0;
        border: 0;
        font-size: 12px;
        color: @tabtext-color;
    }

    .loginTabContainer .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
        border-radius: 0 0 0 20px !important;
        display: inline-block;
        flex: 1;
        text-align: center;
        font-weight: 600;
        background-color: @white;
        height: 54px;
        line-height: 34px;
        margin: 0;
        border: 0;
        text-align: center;
        font-size: 12px;
    }

    .loginTabContainer .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
    .loginTabContainer .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
        background-color: @primary-color !important;
        outline: 0;
        margin: 0;
        font-size: 12px;
    }

    .loginTabContainer .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
    .loginTabContainer .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: @white;
        font-weight: 500;
    }

    .loginTabContainer .ant-tabs-nav>div:nth-of-type(1) {
        display: unset !important;
        width: auto !important;
    }


    button[type="submit"] {
        height: 44px;
        border-radius: 4px;
        font-size: 14px;
    }

    button[type="button"] {
        height: 44px;
        border-radius: 4px;
        font-size: 14px;
    }

    .loginTabContainer .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
    .loginTabContainer .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
        transition: none !important;
    }

    .loginyonetimpaneli {
        font-weight: 700;
        font-size: 20px;
    }

    .bufi_login_logo {
        width: 30px;
        height: 30px;
        background: url(/assets/media/logo.svg);
        border-radius: 14.5px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-right: 10px;
    }

    .forgotpassword_container {
        background-color: @backgroundcolor !important;

        .ant-card.ant-card-bordered {
            border: 1px solid @tabborder-color;
            border-radius: 20px;
            overflow: hidden;
        }

        .ant-tabs-nav-more {
            display: none;
        }

        .header_row {
            div {
                margin: 0 auto 0 0;

                h4 {
                    font-weight: 600;
                }
            }

            span {
                text-align: center;
                left: 0;
                top: 25%;
                position: absolute;
            }
        }

        .ant-card {
            background-color: @backgroundcolor;
        }

        .codeverify_input {
            border-bottom: 1px solid @input-border;
            font-weight: 600;
            outline: 0;
            width: 8rem;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            letter-spacing: 8px;
        }

        .special_green_button {
            background: @button-success ;
            border-color: @button-success ;
        }

    }

}



/* Sidebar Menü */
.sidebar-menu {

    .collapse {

        .collapse-btn {
            position: absolute;
            margin-left: 62%;
            z-index: 1;
            border: none;
            border-bottom-left-radius: 20px;
            cursor: pointer;
            transition: none !important;
            box-shadow: none !important;
        }

        .uncollapse-btn {
            position: absolute;
            border: none;
            margin-left: 82%;
            transition: none !important;
            box-shadow: none !important;
        }
    }

    .profile {
        width: 70%;
        margin: 7% 10%;

        .collapsed-user-icon {
            padding: 10px;
            margin-top: 10px;
            width: 70px;
            height: 70px;
        }

        .profile-btn {
            border: none;
            color: @secondary-color;
            transition: none !important;
            box-shadow: none !important;
            margin-top: -15px;
        }

        .user-name {
            padding-top: 13px;
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 600;
            line-height: 24px;
        }

        .user-mail {
            margin-top: 0px;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
        }

        .user-icon {
            padding: 10px;
            width: 70px;
            height: 70px;
        }

        .profile-icon-btn {
            padding-top: 25px;
            padding-left: 10px;
            cursor: pointer;
        }
    }

    .search-btn {

        .search-collapsed-btn {
            background-color: #F5F3FF;
            height: 40px;
            width: 80%;
            margin-left: 10%;
            border-radius: 8px;
            border: none;
            margin-top: -10px;

            .search-collapsed-icon {
                font-size: 18px;
                color: #C4C4C4;
            }
        }

        .search-uncollapsed-btn {
            width: 81% !important;
            margin-left: 10% !important;
            margin-bottom: 3% !important;
            background-color: #F5F3FF !important;
            border: none !important;
            border-radius: 8px !important;
            margin-top: -10px;

            .search-uncollapsed-icon {
                color: #C4C4C4;
                font-size: 20px;
            }
        }

        .search-uncollapsed-btn ::placeholder {
            background-color: #F5F3FF;
            border: none !important;
            outline: 0 !important;
        }
    }

    .menu {
        padding: 0 10%;
    }

    .logout {
        margin-top: 30px;

        .logout-collapsed-confirm {
            width: 25%;
        }

        .logout-collapsed-btn {
            color: @secondary-color;
            border: none;
            margin-left: 27px;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;

            .logout-collapsed-icon {
                font-size: 18px;
            }
        }

        .logout-uncollapsed-confirm {
            width: 25%;
        }

        .logout-uncollapsed-btn {
            color: @secondary-color;
            border: none;
            margin-left: 38px;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;

            .logout-uncollapsed-icon {
                font-size: 20px;
            }
        }
    }

    .theme {
        background-color: #f0f0f0;
        width: 80%;
        margin: 10% auto;
        border-radius: 20px;

        .theme-uncollapsed-light-btn {
            border: none;
            border-radius: 20px;
            margin: 5px;

            .theme-uncollapsed-light-icon {
                margin-right: 3px;
            }
        }

        .theme-uncollapsed-dark-btn {
            border: none;
            border-radius: 20px;
            margin-left: 9%;

            .theme-uncollapsed-dark-icon {
                margin-right: 3px;
            }
        }

        .theme-collapsed-light-btn {
            border-radius: 20px;
            margin: 5px;
            border: none;

            .theme-collapsed-light-icon {
                margin-right: 0px;
            }
        }

        .theme-collapsed-dark-btn {
            border-radius: 20px;
            border: none;
            margin: 5px 0 5px 5%;

            .theme-collapsed-dark-icon {
                margin-right: 3px;
            }
        }
    }

}

/* UserEditPage */
.user-profile-card {
    padding-bottom: 30px !important;

    .user-img {
        width: 170px;
        height: 170px;
        margin-top: -10px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .mb-7 {
        margin-bottom: 7px;
    }

    .fw-800 {
        font-weight: 800;
    }

    .checkOutLined-icon {
        color: blue;
        font-size: 15px;
        margin-left: 5px;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .box {
        border: 1px dashed gray;
        border-radius: 8px;

        .box-firstrow {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 4px;
        }

        .row {
            margin-left: -15px;
            margin-top: -10px;
            text-align: center;
            margin-bottom: -10px;
            padding-left: 25px;
        }
    }

    .right-col {
        margin-top: 10px;
    }
}

.overview-card {

    .title {
        font-weight: 800;
        font-size: 18px;
    }

    .edit-profile-btn {
        border: none;
        color: white;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .fw-600 {
        font-weight: 600;
    }

    .gray-color {
        color: #a4a8ba;
    }

    .mb--5 {
        margin-bottom: -5px;
    }

    .gsm-input {
        display: inline-block;
        margin-right: 10px;
    }

    .verified-btn {
        display: inline-block;
        border: none;
        background-color: green !important;
        border-radius: 4px;
        color: white !important
    }

    .mt-30 {
        margin-top: 30px;
    }
}

.settings-card {

    .title {
        margin-top: 30px !important;

        .title-p {
            font-weight: 800;
            font-size: 18px;
            margin: 0
        }
    }

    .footer-btn {
        border: none;
    }

    .user-info-card-content {

        .user-info-card-avatar-p {
            margin-top: 70px;
            margin-left: 15px;
            font-size: 15px;
            font-weight: 400;
        }

        .user-info-card-avatar {

            .user-info-card-avatar-img {
                width: 200px;
                height: 200px;
                margin-top: -20px;
            }
        }

        .user-info-card-form {

            .user-info-card-form-input {
                background: #f5f8fa;
                border: none;
                border-radius: 8px;
            }
        }
    }

    .login-info-card-content {

        .login-info-card-form {

            .login-info-card-input {
                background-color: #f5f8fa;
                border: none;
                border-radius: 8px;
                display: inline-block;
                width: 70%;
            }

            .login-info-card-btn {
                display: inline-block;
                margin-left: 10%;
            }
        }
    }

    .company-info-card-content {

        .company-info-card-form {

            .company-info-card-input {
                background-color: #f5f8fa;
                border: none;
                border-radius: 8px;
            }
        }
    }

    .address-info-card-content {

        .address-info-card-form {

            .address-info-card-input {
                background-color: #f5f8fa;
                border: none;
                border-radius: 8px;
            }
        }
    }

    .preference-info-card-content {}

}

.filter-btn {
    border: 1px dashed @primary-color !important;
    border-radius: 20px !important;
    transition: none !important;
    box-shadow: none !important;
}

.filter-icon {
    margin-left: 8px !important;
}

.none-border-shadow {
    border: none !important;
    box-shadow: none !important;
}

.no-filter-btn-style {
    color: rgba(0, 0, 0, 0.65) !important;
    background-color: white !important;
}

.filter-btn-style {
    color: white !important;
    background-color: @primary-color !important;
}

.filter-delete-icon {
    font-size: 18px !important;
    color: @primary-color !important;
}

.user-overview-col {
    margin-bottom: 10px !important;
}

.flex-btw {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f5f8fa !important;
}

.on-sale{
    border:1px solid green; 
    border-radius:8px; 
    margin-right:10px;
    background-color:white;
    color:green; 
    padding:2px;
}

.not-on-sale {
    border:1px solid #f64e60;
    border-radius:8px; 
    margin-right:10px; 
    background:#f64e60;
    color:white;
    padding:2px;
}

.mt-3 {
    margin-top: 3px;
}
//Catalog/product-stock/edit/pictures
.mock-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 15px 0px;

    &.defaultimage {
        border: 1px solid green;
    }

    & .img {
        flex-shrink: 0;
        display: inline-block;
        border-radius: 8px;
        height: 100px;
        object-fit: contain;
    }

    & .preview {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s;
        border-radius: 8px;
        max-width: 100%;
        max-height: 100%;
    }

    &:hover .preview {
        opacity: 1;

        svg {
            color: white;
        }
    }
}

.product-stock_input_border {
    border-radius: 8px;

    .ant-input {
        border: 1px solid #d9d9d9 !important
    }
}

.mb-10{
    margin-bottom: 10px !important;
}

.mt-10 {
    margin-top: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.w-75 {
    width: 75%;
}

.mb-5{
    margin-bottom: 5px !important;
}
.ml-auto{
    margin-left: auto !important;
}

.mr-7 {
    margin-right: 7px !important;
}

.ml-15 {
    margin-left: 15%;
}

.installments_table {
    .table_form_item {
        margin: 0;
    }
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-30{
    margin-top: 30px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.fw-600{
    font-weight: 600;
}

.catalog_upload {
    .ant-upload-list-item-card-actions-btn.ant-btn-sm {
        display: none;
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 4px !important;
        height: 4px !important;
    }
}

.rounded {
    border-radius: 8px !important;
}

.mt--20-mb-10 {
    margin-top: -20px !important;
    margin-bottom: 10px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mt-15 {
    margin-top: 15px;
}
@primary-color: #854075;@secondary-color: #6E3564;@input-bg: #f5f8fa;@link-color: #854075;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: null;