@import '~antd/es/style/themes/default.less';
@import "~antd/dist/antd.less";

.site-layout {
    min-height: 100vh;
}

.site-layout .site-layout-background {
  background: @primary-color;
  padding: 0 15px;
  top: 0;
}

.mainContent {
    margin: 0 30px;
    padding: 30px 0;
}

.site-page-header {
    border-bottom: 1px solid rgb(235, 237, 240);
    background-color: #fff;
}

.ant-layout-sider {
  min-height: calc(100vh - 80px) !important;
  flex: 0 0 270px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  width: 270px !important;
  background-color: white !important;  // sidebarın arka plan rengi
}

// DARK MENU START
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: @secondary-color !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-back-top-content {
  background-color: @primary-color !important;
}
// DARK MENU END

// LIGHT MENU START 
.ant-menu.ant-menu-light,
.ant-menu-light .ant-menu-sub,
.ant-menu.ant-menu-light .ant-menu-sub {
  background-color: #FFFFFF !important;  
  color:@secondary-color !important;
}

.ant-menu-light.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ECEEF5 !important;  
  color: @secondary-color !important;
}

.menu-div .ant-menu-light.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected:first-child:not(.ant-menu-submenu .ant-menu-item) {
  background-color: @secondary-color !important;  
  color:white !important;
}

.menu-div .ant-menu-light.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected:first-child:not(.ant-menu-submenu .ant-menu-item) svg { 
  color:white !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-submenu-active {
  background-color: #F5F3FF !important;   
  color:#7B3D6D !important;
}

.ant-menu-light .ant-menu-submenu-selected {
  color: #F5F3FF !important;
  background-color: #6E3564 !important;
  border-radius: 8px;
}

.ant-menu-light .ant-menu-submenu-open svg {
  color: #7B3D6D !important;
}

.ant-menu-light .ant-menu-submenu-open .ant-menu-submenu-title svg,
.ant-menu-light .ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: @secondary-color !important;
}

.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title svg,
.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: #F5F3FF !important;
}

.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title:hover{
  color: #F5F3FF !important;
}

.ant-menu-light .ant-menu-item-icon{
  color:@secondary-color !important;
}

.ant-menu-light .ant-menu-submenu-arrow {
  color:#8692A6 !important;
}

.ant-menu-light .ant-menu-submenu-selected .ant-menu-item {
  background-color: white !important;
  margin-top: 3% !important;
}

.ant-menu-light.ant-menu-inline .ant-menu-item::after,
.ant-menu-light.ant-menu-vertical .ant-menu-item::after,
.ant-menu-light.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-light.ant-menu-vertical-right .ant-menu-item:after {
  border-right: solid 5px @secondary-color !important;
}
.ant-menu-inline {
  border:0;
}
// LIGHT MENU END

.ant-layout-sider-collapsed {
    min-height: calc(100vh - 80px) !important;
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
}

.ant-breadcrumb + .ant-page-header-heading {
    margin-top: 0 !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
  border-right: solid 8px #c906a2 !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-right: 96%;
}

.symbol > img,
.ant-image-img {
    width: 100%;
    flex-shrink: 0;
    display: inline-block;
    border-radius: 0.475rem;
}

.symbol.symbol-50px > img {
    width: 50px;
    height: 50px;
}

.product {
    width: 50px;
    height: 75px !important;
    object-fit: cover;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #e4e6ef;
    border-radius: 0.42rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.symbol.symbol-circle,
.symbol.symbol-circle > img,
.symbol.symbol-circle .symbol-label {
    border-radius: 50%;
}

.ant-breadcrumb a,
.ant-breadcrumb .text-muted {
    color: #a1a5b7 !important;
    font-size: 12px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ecdfe9;
}

.ant-form-item-explain-error {
    font-size: 12px;
    margin-bottom: 7px;
    margin-top: 5px;
}

.ant-breadcrumb-separator {
    margin: 0 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
}

.font-weight-bold {
    font-weight: 500 !important;
}

.font-size-xs {
    font-size: 0.8rem;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-size-sm {
    font-size: 0.925rem;
}

.productRate {
    font-size: 16px !important;

  .ant-rate-star:not(:last-child) {
    margin-right: 4px !important;
  }
}

.ant-input.ant-input-lg{
  background-color: #F5F3FF;
}

.product_add_table {
  td {
    padding: 4px !important;
    input {
      margin-top: 1.5rem;
    }
  }
}

.ant-input{
  background-color: @input-bg !important;
  border-radius: 8px !important;
  display: inline-block !important;
  border: 1px solid #d9d9d9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-input-number{
  border-radius: 8px !important;
}

.ant-input-affix-wrapper{
  background-color: @input-bg !important;
  border-radius: 8px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: @input-bg !important;
  border-radius: 8px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none !important;
  box-shadow: none !important;
}
.ant-picker{
  border-radius: 8px !important;
  background-color: @input-bg !important;
}

.ant-input-prefix {
  margin-right: 0 !important;
}

@primary-color: #854075;@secondary-color: #6E3564;@input-bg: #f5f8fa;@link-color: #854075;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: null;